import React, {useState} from 'react';
import SelectBox from "./selectbox";
import styles from "./App.module.css";
import hero from "./hero.jpg";
import Checkbox from "./checkbox";
import mobiles from "./mobiles.png";

const server = "https://app.meddiapp.com/zadej-lekare";

const relatives = [
  {id: 1, label: "partner/partnerka"},
  {id: 2, label: "manžel/manželka"},
  {id: 3, label: "dieťa"},
];

function App() {
  const [userName, setUserName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [family, setFamily] = useState([]);
  const [sent, setSent] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeData, setAgreeData] = useState(false);
  const [validated, setValidated] = useState(false);

  const privacyText = <>súhlasím s <a href={"ochrana.pdf"}>pravidlami spracovania a ochrany osobných údajov</a></>;
  const dataText = <>súhlasím so <a href={"zpracovani.pdf"}>spracovaním osobných údajov</a></>;

  const updateFamily = (i, member) => {
    let newFamily = [...family];
    newFamily[i] = member;
    setFamily(newFamily);
  }

  const addMember = () => {
    const newFamily = [...family, {
      name: "",
      relative: 0,
      isSameDoctor: false,
      doctorName: "",
      doctorPhone: "",
      doctorAddress: ""
    }];
    setFamily(newFamily);
    return false;
  }

  const isFormValid = () => {
    return !agreePrivacy || !agreeData || !userName || !doctorName || !doctorAddress || !doctorPhone;
  }

  const send = async () => {
    if (isFormValid()) {
      setValidated(true);
      return;
    }

    const data = {
      name: userName,
      doctorName, doctorPhone, doctorAddress,
      company: 1,
      family: family.map(f => ({
        type: f.relative,
        name: f.name,
        doctorName: f.isSameDoctor ? doctorName : f.doctorName,
        doctorAddress: f.isSameDoctor ? doctorAddress : f.doctorAddress,
        doctorPhone: f.isSameDoctor ? doctorPhone : f.doctorPhone,
      }))
    };
    console.log("tu", data);
    const response = await fetch(server, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    setSent(true);
    return false;
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <h1>zadajlekara.sk</h1>
        </div>
      </header>
      <div className={styles.heroImage}>
        <img src={hero} alt={""}/>
        <div className={styles.wrapper}>
          {sent ?
            <div className={styles.responseContainer}>
              Dotazník bol v poriadku<br/>
              odoslaný, ďakujeme,<br/>
              že používate
              <span className={styles.green}> ordináciu bez hraníc.</span>
            </div>
          : <div className={styles.messageContainer}>
              Odporučte svojho lekára,<br />
              ktorému <span className={styles.green}>dôverujete.</span>
            </div>}
        </div>
      </div>
      {!sent && <form>
        <div className={styles.primaryContainer}>
          <div className={styles.wrapper}>
            <h2>Vyplňte kontaktné údaje Vášho lekára</h2>
            {validated && !doctorName && <div className={styles.validation}>Toto políčko je nutné vyplniť</div> }
            <label>
              <span className={"required"}>*</span>Meno lekára
              <input value={doctorName} onChange={e => setDoctorName(e.target.value)}/>
            </label>
            {validated && !doctorAddress && <div className={styles.validation}>Toto políčko je nutné vyplniť</div> }
            <label>
              <span className={"required"}>*</span>Adresa lekára
              <input value={doctorAddress} onChange={e => setDoctorAddress(e.target.value)}/>
            </label>
            {validated && !doctorPhone && <div className={styles.validation}>Toto políčko je nutné vyplniť</div> }
            <label>
              <span className={"required"}>*</span>Telefón lekára
              <input value={doctorPhone} onChange={e => setDoctorPhone(e.target.value)}/>
            </label>
          </div>
        </div>
        <div className={styles.familyContainer}>
          <div className={[styles.wrapper, styles.rowFlex].join(" ")}>
            <div className={styles.left}>
              <h3>Údaje o Vašej osobe</h3>
              <label>
                {validated && !userName && <div className={styles.validation}>Toto políčko je nutné vyplniť</div> }
                <span className={"required"}>*</span>Vaše meno a priezvisko
                <input value={userName} onChange={e => setUserName(e.target.value)}/>
              </label>
              {/* <button className={styles.secondaryButton} type="button" onClick={() => addMember()}>přidat dalšího člena
                rodiny
              </button> */}
              {family.length > 0 && family.map((f, i) => <FamilyMember key={i} index={i} {...f} onChange={updateFamily}/>)}
              {validated && !agreePrivacy && <div className={styles.validation}>Toto políčko je nutné zaškrtnúť</div> }
              <Checkbox label={privacyText} onChange={() => setAgreePrivacy(!agreePrivacy)} checked={agreePrivacy}/>
              {validated && !agreeData && <div className={styles.validation}>Toto políčko je nutné zaškrtnúť</div> }
              <Checkbox label={dataText} onChange={() => setAgreeData(!agreeData)} checked={agreeData}/>
              <button className={styles.primaryButton} type="button" onClick={send}>odoslať</button>
            </div>
            <div className={styles.right}>
              <img src={mobiles} alt={"Mobile phones"}/>
            </div>
          </div>
        </div>
      </form>}
    </>
  );
}

function FamilyMember({index, relative, name, isSameDoctor, doctorName, doctorAddress, doctorPhone, onChange}) {
  const internalChange = (k, v) => {
    onChange(index, {
      name, relative, isSameDoctor, doctorName, doctorPhone, doctorAddress, [k]: v
    });
  }
  return (
    <div>
      <h3>Ďalší člen rodiny</h3>
      <label>
        <span className={"required"}>*</span>Vzťah k osobe
        {/*<input value={relative} onChange={e => internalChange("relative", e.target.value)}/>*/}
        <SelectBox label={relatives.find(r => r.id === relative)?.label} values={relatives} width={300} onChange={e => internalChange("relative", e)}/>
      </label>
      <label>
        <span className={"required"}>*</span>Meno a priezvisko
        <input value={name} onChange={e => internalChange("name", e.target.value)}/>
      </label>
      <Checkbox label={"doporučuje stejného lékaře"} checked={isSameDoctor} onChange={e => internalChange("isSameDoctor", e)}/>
      <label>
        <span className={"required"}>*</span>Meno lekára
        <input value={doctorName} onChange={e => internalChange("doctorName", e.target.value)}/>
      </label> <label>
      <span className={"required"}>*</span>Adresa lekára
      <input value={doctorAddress} onChange={e => internalChange("doctorAddress", e.target.value)}/>
    </label> <label>
      <span className={"required"}>*</span>Telefón lekára
      <input value={doctorPhone} onChange={e => internalChange("doctorPhone", e.target.value)}/>
    </label>
    </div>
  );
}

export default App;
